import * as React from "react";
import { Link } from "react-router-dom";
import { GET_PERSON_ROUTE } from "../../../../lib/Router/routes";
import { GetPersonFullName } from "../../../../helpers/personHelpers";
import { IPersonResultHit } from "../../../../interfaces/algolia/ISearchResultHit";
import { GetFormattedDateByIDateObject } from "../../../../helpers/dateHelpers";
import { IPageSource } from "../../../../interfaces/domain/common/ISearch";

type ReferencePersonResultHitProps = {
  hit: IPersonResultHit;
  onClick?: () => void;
  pageSource: IPageSource;
};

const ReferencePersonResultHit: React.FunctionComponent<ReferencePersonResultHitProps> = (props) => {
  const person = props.hit;
  const birthDateString = person.birthDate ? `Født: ${GetFormattedDateByIDateObject(person.birthDate)}` : "";
  const relatedPersonReference = person.references?.find((x) => x.id === props.pageSource.id);

  return (
    <Link
      to={GET_PERSON_ROUTE(person.id)}
      className="list-group-item list-group-item-action"
      onClick={() => {
        if (props.onClick) {
          props.onClick();
        }
      }}>
      <div className="media d-flex align-items-center">
        <div className="media-body">
          <div className="row">
            <div className="col">
              <div>
                <span className=" h6">
                  {GetPersonFullName(person.firstName, person.middleName, person.lastName)}{" "}
                </span>
              </div>
              <div className="text-muted">
                {birthDateString}
              </div>
            </div>
            <div className="col text-muted text-break">{relatedPersonReference?.cComment}</div>
            <div className="col text-muted text-break">{relatedPersonReference?.cUrl}</div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default ReferencePersonResultHit;
